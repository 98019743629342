import React from 'react'
import '../../../../scss/_chevron.scss'


export default (props) => 
    <svg {...props} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13" cy="13" r="13" />
        <path d="M11 8.75739L15.2426 13L11 17.2427" stroke="#1C4042" strokeWidth="2"/>
    </svg>

