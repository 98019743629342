import * as React from "react";
import { LinkWithUTM as Link } from './../LinkWithUTM'

import "./newsblock.scss";
import ChevronRight from "../icons/Approach/ChevronRight";

export const NewsblockSection = ({className, children}) => (
    <section className={`carer-section carousel ${className}`}>
        {children}
    </section>
)

export const NewsblockContent = ({children, ...props}) => (
    <div className={`carer-section-blocks ${props.className}`}>
        {children}
    </div>
)

export const NewsblockBlocks = ({children}) => (
    <div className="info-block-section left">
        {children}
    </div>
)

export const NewsblockButton = ({children, ...props}) => (
    <button className="btn-primary load-more-btn" {...props}>{children}</button>
)

export const NewsblockTitle = ({children, center, displaySmall }) => (
    <h1 className={`${displaySmall ? ' newblock-title--small' : ''} ${center ? ' newblock-title--center' : ''}`}>
        {children}
    </h1>
)

export const NewsblockContainer = (props) => {
    return(
        <section className={`carer-section carousel ${props.className}`}>
            <div className={`carer-section-blocks ${props.className}`}>
                <h1>{props.title}</h1>
                <div className="info-block-section">
                    {props.children}
                </div>
            </div>
            <Link to="/cta/" className="button-link">
                <button className="btn-primary load-more-btn">Load more</button>
            </Link>
        </section>
    )
}

export const Newsblock = (props) => {
    return (
        <Link to={props.link} className={`info-block-content slide active ${props.className}`}>
            <div className={`infoblock-carer sec1 fade read-more ${props.color} ${props.className}`}>
                {props.children}
                <div className={`info-block-text ${props.className} ${props.displayShort ? ' info-block-text--short' : ''}`}>
                    {props.date && <p className="secondary info-block-text_date">{props.date}</p>}
                    <h3>{props.title}</h3>
                    {props.description && <p className="primary info-block-text_paragraph">
                        {props.description}
                    </p>}
                    <span className="info-read" to={props.link}>
                        Read more
                        <ChevronRight />
                    </span>
                </div>
            </div>
        </Link>
    )
}