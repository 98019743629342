import React, {useState, useRef, useEffect, useCallback} from 'react'
import '../../scss/_accordion.scss'
import DefaultChevron from '../components/icons/Accordion/DefaultChevron'
import PropTypes from "prop-types"



/**
 * Accordion component
 *
 * @component
 * @example
 * <Accordion title="Title" transition={[1000, "ease-in-out"]}>
 *  <p>Lorem Ipsum...</p>
 * </Accordion>
 */
function Accordion(props){
    const transitionStyle = `${props.transition[0]}ms ${props.transition[1]}`;
    const [isExpanded, setIsExpanded] = useState(props.isExpandedDefault)
    const expandableDiv = useRef(null)
    const [currentHeight, setCurrentHeight] = useState(0);
    const [isTransitionInProgress, setIsTransitionInProgress] = useState(false)
    const [contentTransition, setContentTransition] = useState(transitionStyle);
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [expClass, setExpClass] = useState('');
    const chevronRef = useRef(null)


    

    useEffect(() => {
        
        if (isFirstRender) {
            if (isExpanded) {setExpClass("final-auto")}
			return setIsFirstRender(false)
		}

        if (isExpanded){
            setIsTransitionInProgress(true)
            setCurrentHeight(expandableDiv.current.scrollHeight);
            setTimeout(()=>{
                setExpClass("final-auto")
                setIsTransitionInProgress(false)
            }, props.transition[0])

            
        }else{
            setIsTransitionInProgress(true)
            setExpClass("")
            setCurrentHeight(0);
            setTimeout(()=>{
                setIsTransitionInProgress(false)
            }, props.transition[0])
            
        }
    }, [isExpanded])

    

    const toggleIsOpen = useCallback(() => {
		setIsExpanded(!isExpanded);
	}, [isExpanded]);

	function expandDiv(){
        if (!isTransitionInProgress){
            return toggleIsOpen();
        }
        return;
    }

    return(
        <div aria-expanded={isExpanded} className={`${props.className} accordion`}>
            <div className="accordion-visible" onClick={()=>expandDiv()}>
                <div 
                    className="accordion-icon"
                    ref={chevronRef}
                    style={{
                        transitionDuration: `${props.iconTransition[0]}ms`,
                        transitionTimingFunction: `${props.iconTransition[1]}`
                    }}
                >
                    <props.renderIcon/>
                </div>
            <div
                className="accordion-title"
            >
                {props.renderTitle ? <props.renderTitle transition={props.transition} isExpanded={isExpanded}/> : ""}
        </div>
           </div>
            <div>
                <div className={"accordion-list " + expClass} ref={expandableDiv} style={{
                    marginLeft: props.indentation + 20 + 26,
                    transition: `${contentTransition}`,
                    height: currentHeight
                }}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

Accordion.propTypes = {
    renderTitle: PropTypes.element,
    indentation: PropTypes.number,
    className: PropTypes.string,
    renderIcon: PropTypes.element,
    transition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    iconTransition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isExpandedDefault: PropTypes.bool
}
  
Accordion.defaultProps = {
    indentation: 15,
    renderIcon: DefaultChevron,
    transition: [300, "ease-in"],
    iconTransition: [300, "ease-in"],
    isExpandedDefault: false,
    className: ""
}


export default Accordion;
