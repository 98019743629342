import React from "react";
import { Helmet } from "react-helmet";
import { Link, useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";
import GreenCheckMark from '../components/icons/GreenCheckMark'
import {CarouselComponent, CarouselComponentSlide} from '../components/carousel';
import "../../scss/main.scss";
import IconTip from "../components/icons/LiveInCare/IconTip";
import Arrow from "../components/icons/LiveInCare/Arrow";
import NotIncluded from "../components/icons/LiveInCare/NotIncluded";
import Included from "../components/icons/LiveInCare/Included";
import BrochureRequestPanel from '../components/BrochureRequestPanel'
import Accordion from "../components/Accordion"
import LiveInCareChevron from "../components/icons/LiveInCare/LiveInCareChevron";
import CqcApproved from '../components/CqcApproved'
import {
	Newsblock,
	NewsblockSection,
	NewsblockContent,
	NewsblockBlocks,
	NewsblockTitle,
} from '../components/Newsblock';
import { SINGLE_TIER_ONE } from "../constants/pricing";



export default function Approach() {
		const { site, relatedPosts: { nodes: newsblockDataUnsorted }  } = useStaticQuery(
		graphql`
		  query {
            site {
                siteMetadata {
                    featureToggles {
                        enableBlogContent
                    }
					appConfig {
                        gtmContainerId
                    }
                }
            }
			relatedPosts: allGhostPost(filter: {slug: {
				regex: "/what-can-a-live-in-carer-help-with|live-in-care-vs-care-home|what-is-the-cost-of-live-in-care/"}
			}) {
				nodes {
					  title
					  slug
				}
			}
		  }
		`
	  )

	  const newsblockData = [
		  newsblockDataUnsorted.find(post => post.slug === 'what-can-a-live-in-carer-help-with'),
		  newsblockDataUnsorted.find(post => post.slug === 'live-in-care-vs-care-home'),
		  newsblockDataUnsorted.find(post => post.slug === 'what-is-the-cost-of-live-in-care')
	  ]

    const { siteMetadata: {
        featureToggles: {
            enableBlogContent,
        },
		appConfig: {
			gtmContainerId
		}
    } } = site

	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Live-in Care</title>
				<meta property="og:title" content="Edyn Care | Live-in Care"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png"/>
				<meta property="og:url" content="https://www.edyn.care/liveincare" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/liveincare" />
				<meta name="twitter:title" content="Edyn Care | Live-in Care" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>

			<div className="container">
				<Header />
				<main className='liveincare-page'>
					<section>
						<div className="livein-landing">
							<StaticImage 
								src="../../static/assets/marketing-site/images/live-in-image-01.jpg" 
								placeholder="blurred"
							/>
							<div className="landing-context">
								<h1>Live-in care is the new normal.</h1>
								<br />
								<p className="primary">
									Live-in care is when a professional carer comes and lives with you in your own home to provide you with care, companionship and support, so you live a better quality of life in the comfort of your own home.
								</p>
								<br />
								<p className="primary">
									20 years ago when families were faced with the realisation
									that their loved one or family member needed care the only
									option was to move into a care home. Live-in care is fast
									becoming the preferred choice for those who wish to receive 1
									to 1 care whilst maintaining the independence of living in
									their own home.
								</p>
								<br />
								<div className="tip">
									<IconTip />
									<p className="secondary">
										According to a survey conducted by the Live-in Care Hub, 97%
										of people given the choice would prefer to carry on living
										at home.
									</p>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="care-benefits wide-view">
							<h1> What can my carer help with </h1>
							<div className="benefit benefit__grid-column-left">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-04.png" height={120} />
								</div>
								<div className="benefit-descrip">
									<h3>Medication management</h3>
									<br />
									<p className="primary">
										Our carers are trained to proactively and efficiently
										administer medication to ensure your wellbeing.
									</p>
								</div>
							</div>
							<div className="benefit benefit__grid-column-right">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-07.png" height={120}/>
								</div>
								<div className="benefit-descrip">
									<h3>Meal preparation</h3>
									<br />
									<p className="primary">
										Carers will plan your meals according to your dietary
										requirements, nutritional needs and personal preferences,
										including cooking your favourite meals and shopping for your
										groceries.
									</p>
								</div>
							</div>
							<div className="benefit benefit__grid-column-left">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-05.png" height={120}/>
								</div>
								<div className="benefit-descrip">
									<h3>Personal hygiene</h3>
									<br />
									<p className="primary">
										Our carers can provide assistance with dressing, grooming,
										bathing and toileting.
									</p>
								</div>
							</div>
							<div className="benefit benefit__grid-column-right">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-02.png" height={120}/>
								</div>
								<div className="benefit-descrip">
									<h3>Support with mobility</h3>
									<br />
									<p className="primary">
										Our carers are trained to ensure they help their clients
										move about safely whether this with mobilities aids or
										hoists.
									</p>
								</div>
							</div>
							<div className="benefit benefit__grid-column-left">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-06.png" height={120}/>
								</div>
								<div className="benefit-descrip">
									<h3>Housekeeping</h3>
									<br />
									<p className="primary">
										Our carers can help out with chores like laundry, light
										cleaning, linen and taking out rubbish.
									</p>
								</div>
							</div>
							<div className="benefit benefit__grid-column-right">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-01.png" height={120}/>
								</div>
								<div className="benefit-descrip">
									<h3>Emotional support</h3>
									<br />
									<p className="primary">
										Your care plan will outline all your social interests and
										preferences and the carer will help you maintain your
										activities and hobbies, both in and out of your home.
									</p>
								</div>
							</div>
							<div className="benefit benefit__grid-column-left">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-03.png" height={120}/>
								</div>
								<div className="benefit-descrip">
									<h3>Companionship</h3>
									<br />
									<p className="primary">
										Carefully matching the right carers for our clients helps
										build long lasting harmonious relationships, so they get to
										know your personality traits and when you need space.
									</p>
								</div>
							</div>
							<div className="benefit custom benefit__grid-column-right">
								<div className="benefit-descrip">
									<p className="secondary">
										We offer specialist support for conditions including:
									</p>
									<div className="benefit-list">
										<ul>
											<li>
												<Link to='/condition/dementia/'>
													<Arrow className="gatsby-image-wrapper"/>
													<p>Dementia</p>
												</Link>
											</li>
											<li>
												<Link to='/condition/parkinsons/'>
													<Arrow className="gatsby-image-wrapper"/>
													<p>Parkinsons</p>
												</Link>
											</li>
											<li>
												<Link to='/condition/multiple-sclerosis/'>
													<Arrow className="gatsby-image-wrapper"/>
													<p>MS care</p>
												</Link>
											</li>
										</ul>
										<ul>
											<li>
												<Link to='/condition/cancer/'>
													<Arrow className="gatsby-image-wrapper"/>
													<p>Cancer care</p>
												</Link>
											</li>
											<li>
												<Link to='/condition/stroke/'>
													<Arrow className="gatsby-image-wrapper"/>
													<p>Stroke care</p>
												</Link>
											</li>
										</ul>
										{/* <ul>

										</ul> */}
									</div>
								</div>
							</div>
						</div>
						<hr className="wide-view" />
					</section>

					<CarouselComponent title="What can my carer help with" className="live-in-care-carousel">
						<CarouselComponentSlide
							slideName="Medication Management" 
							slideText="Our carers are trained to proactively and efficiently administer medication to ensure your wellbeing." 
						>
							<StaticImage placeholder="blurred"  src="../../static/assets/marketing-site/images/livein-icon-04.png" alt="medication management" height={200} quality={'100'} objectFit={`contain`}  />
						</CarouselComponentSlide>

						<CarouselComponentSlide
							slideName="Meal Preparation" 
							slideText="Carers will plan your meals according to your dietary requirements, nutritional needs and personal preferences, including cooking your favourite meals and shopping for your groceries." 
						>
								<StaticImage placeholder="blurred" objectFit={`contain`} src="../../static/assets/marketing-site/images/livein-icon-07.png" height={200} quality={'100'} />
						</CarouselComponentSlide>

						<CarouselComponentSlide
							slideName="Personal hygiene" 
							slideText="Our carers can provide assistance with dressing, grooming, bathing and toileting." 
						>
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-05.png" height={200} quality={'100'} objectFit={`contain`}  />
						</CarouselComponentSlide>
						<CarouselComponentSlide
							slideName="Mobility Support" 
							slideText="Our carers are trained to ensure they help their clients move about safely whether this with mobilities aids or hoists." 
						>
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-02.png" height={200} quality={'100'} objectFit={`contain`} />
						</CarouselComponentSlide>
						<CarouselComponentSlide
							slideName="Housekeeping" 
							slideText="Our carers can help out with chores like laundry, light cleaning, linen and taking out rubbish." 
						>
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-06.png" height={200} quality={'100'} objectFit={`contain`} />
						</CarouselComponentSlide>
						<CarouselComponentSlide
							slideName="Emotional support" 
							slideText="Your care plan will outline all your social interests and preferences and the carer will help you maintain your activities and hobbies, both in and out of your home. " 
						>
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-01.png" height={200} quality={'100'} objectFit={`contain`} />
						</CarouselComponentSlide>
						<CarouselComponentSlide
							slideName="Companionship" 
							slideText="Carefully matching the right carers for our clients helps build long lasting harmonious relationships, so they get to know your personality traits and when you need space." 
						>
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-03.png" height={200} quality={'100'} objectFit={`contain`} />
						</CarouselComponentSlide>
					</CarouselComponent>
					<div className="care-benefits mobile">
						<div className="benefit mobile-view">
							<div className="benefit-descrip">
								<p className="secondary">
									We offer specialist support for conditions including:
								</p>
								<div className="benefit-list">
								<ul>
									<li>
										<Link to='/condition/dementia/'>
											<Arrow className="gatsby-image-wrapper"/>
											<p>Dementia</p>
										</Link>
									</li>
									<li>
										<Link to='/condition/parkinsons/'>
											<Arrow className="gatsby-image-wrapper"/>
											<p>Parkinsons</p>
										</Link>
									</li>
									<li>
										<Link to='/condition/multiple-sclerosis/'>
											<Arrow className="gatsby-image-wrapper"/>
											<p>MS care</p>
										</Link>
									</li>
								</ul>
								<ul>
									<li>
										<Link to='/condition/cancer/'>
											<Arrow className="gatsby-image-wrapper"/>
											<p>Cancer care</p>
										</Link>
									</li>
									<li>
										<Link to='/condition/stroke/'>
											<Arrow className="gatsby-image-wrapper"/>
											<p>Stroke care</p>
										</Link>
									</li>
								</ul>
								</div>
							</div>
						</div>
					</div>
					<hr className="benefits mobile"/>


					<section>
						<div className="livein-reasons">
							<h1 className="mobile-view">The benefits of live-in care</h1>

							<div className="livein-images">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-imageclus-02.jpg" />
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-imageclus-03.jpg" />
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-imageclus-04.jpg" />
								<StaticImage placeholder="blurred"
									className="stretch"
									src="../../static/assets/marketing-site/images/livein-imageclus-01.png"
								/>
								<StaticImage placeholder="blurred"
									className="stretch"
									src="../../static/assets/marketing-site/images/livein-imageclus-05.png"
								/>
							</div>
							<div className="livein-context">
								<h1>
									The benefits of{" "}
									<br />
									live-in care
								</h1>
								<br />
								<ul>
									<li>
										<GreenCheckMark className='livein-reasons__green-check-mark' />
										<div className="livein-list">
											<h3>Comfort at home</h3>
											<br />
											<p className="primary">
												Enduring the stress, upheaval and uncertainty of moving
												into a care home is tough. There is no place like home
												with it’s familiarity and possessions around you.
											</p>
										</div>
									</li>
									<li>
										<GreenCheckMark className='livein-reasons__green-check-mark' />
										<div className="livein-list">
											<h3>Tailored to you</h3>
											<br />
											<p className="primary">
												Our carefully matched carers get to know your loved
												one’s like they were a family member.
											</p>
										</div>
									</li>
									<li>
										<GreenCheckMark className='livein-reasons__green-check-mark' />
										<div className="livein-list">
											<h3>Maintaining your lifestyle</h3>
											<br />
											<p className="primary">
												One-to-one care enables your loved one to live the life
												they wish - no rigid timetables or set routines.
												Continue to live to their rhythm with the additional
												help they require.
											</p>
										</div>
									</li>
									<li>
										<GreenCheckMark className='livein-reasons__green-check-mark' />

										<div className="livein-list">
											<h3>With professional carers</h3>
											<br />
											<p className="primary">
												It’s no surprise the calibre of carers and our training
												sets us apart: only 5% of applicants meet our standards.
												We then train them in-house to ensure they understand
												our values.
											</p>
										</div>
									</li>
									<li>
										<GreenCheckMark className='livein-reasons__green-check-mark' />

										<div className="livein-list">
											<div className="livein-list">
												<h3>More affordable</h3>
												<br />
												<p className="primary">
													Surprisingly to many live-in care is often more cost
													effective than people initially realise. A popular
													misconception is that 24 hour care is prohibitively
													expensive when compared to the cost of a care home.
													<br />
													<br />
													Care home: £1,500 / Live-in care: starting at {SINGLE_TIER_ONE}
												</p>
												<Link to="/pricing/" className="button-link">
													<button className="btn-primary">
														Learn more about our pricing
													</button>
												</Link>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</section>

					<section>
                        <BrochureRequestPanel
							gtmContainerId={gtmContainerId}
                            quoteImage={(
                                <StaticImage placeholder="blurred"
                                    src="../../static/assets/marketing-site/images/banner-brochure-img.png"
                                    objectFit='contain'
                                    height={86}
                                />
                            )}
                            quoteText={`
                            Care homes used to be the default for individuals who are in
                            need of care, but now 99% of those in their later years can
                            stay at home with regulated live-in care. Edyn’s service is
                            changing care
                            `}
                            clientDescription={(
                                <>
                                    <p>Jai Smith</p>
                                    <p>Dementia live-care</p>
                                </>
                            )}
                        />
                        {/* leaving this section commented out for now - we have an open question about what we do with the design here, we may want to bring this back */}
						{/* <BackgroundImage className="livein-banner" fluid={bgImg.childImageSharp.fluid}>
							<div className="banner-brochure">
								<StaticImage
                                    placeholder="blurred"
									src="../../static/assets/marketing-site/images/banner-brochure.png"
									objectFit='contain'
								/>
								<h3>
									Discover what exceptional live-in care looks like with our
									brochure
								</h3>
								<Link to="" className="button-link">
									<button className="btn-primary">Read brochure</button>
								</Link>
							</div>
							<div className="banner-quote">
								<QuotationMark />
								<h3 className="small">
									”
								</h3>
								<div className="customer-images">
									<StaticImage placeholder="blurred"
										src="../../static/assets/marketing-site/images/banner-brochure-img.png"
										objectFit='contain'
										height={86}
									/>
									<div className="customer-names">
										<p className="secondary pro">
											Jai Smith
											<br />
											<span>
												Edyn customer <br />
												Dementia live-care
											</span>
										</p>
									</div>
								</div>
							</div>
						</BackgroundImage> */}
					</section>

					<section>
						<div className="livein-compare">
							<h2>How we compare</h2>

							<p className="secondary heading no-border">Care home</p>
							<p className="secondary heading no-border">
								Introductory agencies
							</p>
							<div className="compare-item green no-border heading">
								<StaticImage placeholder="blurred"
									style={{width: '50%'}}
									src="../../static/assets/marketing-site/images/logo.png"
									objectFit='contain'
								/>
							</div>

							<p className="secondary sub-heading">Trained carers</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<NotIncluded className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>

							<p className="secondary sub-heading">1:1 support</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<NotIncluded className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>

							<p className="secondary sub-heading">Dedicated care team</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<NotIncluded className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>

							<p className="secondary sub-heading">Comfort of your own home</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<NotIncluded className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>

							<p className="secondary sub-heading">Connect to your community</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<NotIncluded className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>

							<p className="secondary sub-heading">
								Oversight of care, nutrition and medication
							</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<NotIncluded className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>

							<p className="secondary sub-heading">Understanding of well being</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<NotIncluded className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<NotIncluded className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>

							<p className="secondary sub-heading">Fully managed</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<NotIncluded className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>

							<p className="secondary no-border sub-heading">Family can stay anytime</p>
							<div className="compare-item no-border mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<NotIncluded className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item no-border mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>
							<div className="compare-item green no-border mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<Included className="gatsby-image-wrapper" />
							</div>
						</div>
					</section>

					<section>
						<div className="livein-faq">
							<div className="faq-heading">
								<h1>FAQs</h1>
							</div>
							<div className="faq-dropdown">
								{/*<Accordian>
									<AccordianItem 
										renderTitle={()=><h1>Test</h1>}
										renderChevron={()=><LiveInCareAccordianChevron/>}
									>
										<p>Lorem Ipsum...</p>
									</AccordianItem>
								</Accordian>*/}

								<Accordion 
									renderTitle={()=><h3>Can your Professional Carers speak English well?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
									className="first"
								>
									<p>We believe communication between our Professional Carers and clients is extremely important. All of our carers are fluent in English, and many speak other languages as well, so do let us know if you're looking for a Professional Carer who can speak a specific language and we will try to match you with them. We meet all our carers in-person at their interview and at our training, so we ensure that their English is of a good standard.</p>
								</Accordion>
								<Accordion 
									renderTitle={()=><h3>Can your Professional Carers drive?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
								>
									<p>Yes, many of our Professional Carers can drive. If you require your carer to drive you to appointments, or to help with errands you’ll need to ensure that the carer is added to your care insurance policy. We do have a surcharge of £40 - £80 per week. </p>
								</Accordion>
								<Accordion 
									renderTitle={()=><h3>What happens when my Professional Carer has time off?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
								>
									<p>We understand the importance of consistency. However, as we are sure you will appreciate, it is important for our carers' own wellbeing to have sufficient time off work and holidays to rest, so that they continue to deliver the highest quality of care you should expect.</p>
									<p>You will have a team of two carers (main carer and respite carer) who will provide care in your home for three weeks main carer then respite for one week. Whilst this is a typical rota pattern we do offer various patterns, including a four-week work period with two weeks off. A team of the same two carers ensure they really get to know and understand you as a person.</p>
									<p>Day breaks: The carer will need to have a two hour break in any 24-hour period, which many clients usually find work best when they are resting in the afternoon. If you require care and support during this period many will have family visits to cover this break, or we can organise for an hourly care provider to cover this for you.</p>
								</Accordion>
								<Accordion 
									renderTitle={()=><h3>What training and background checks do you do on your Professional Carers?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
								>
									<p>All of our Professional Carers complete our unrivalled training programme at our office in London - far exceeding statutory requirements and receive on-going training and professional development. We believe in quality not quantity.</p>
									<p>We also require all our carers to complete enhanced DBS checks, identity checks and references checks. Our rigorous background checks means that we ensure quality and safety.</p>
								</Accordion>
								<Accordion
									renderTitle={()=><h3>How long does it take to organise care?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
								>
									<p>We usually say it takes about 5 days to get care in place - this includes your care consultation, care assessment, matching your carer and finalising documentation. That said, we can move quicker if required.</p>
								</Accordion>
								<Accordion
									renderTitle={()=><h3>Will I get to meet my Professional Carer before care starts?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
								>
									<p>Yes, we highly recommend meeting your Professional Carer before starting care. When we propose Professional Carers to you we give you access to their digital CV which has information about their life, interests and care experience. You can then decide which ones you’d like to speak to further to ensure we find the right match for you and your family. This usually takes place on-line via zoom or facetime.</p>
								</Accordion>
								<Accordion
									renderTitle={()=><h3>Do you create care plans?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
								>
									<p>After speaking to one of our family care advisors, the next step is to complete an in-depth care assessment with one of our care managers. We complete these for all of our clients, so we can get to know their preferences, care needs and desired care outcomes. Generally, this is a 1 - 2 hour meeting in-person in which our care managers create a comprehensive care plan with input from not just the person receiving care, but with vital input from family members and other healthcare professionals involved in your care.</p>
									<p>Depending on the care plan devised, there will be elements of encouragement, monitoring and actively assisting with the care tasks. Importantly, it will not just consider your care needs but your choices on how you live your life, your preferences and the social support required. Our care plans are person-centred and outcome-driven which focus on enhancing health and wellbeing, whilst improving overall quality of life.</p>
									<p>Our care plans build a daily schedule which clearly articulates the expectations for our clients and Professional Carers. The schedule will take into account any night time assistance. One of the benefits of having a live-in carer is that someone is there in case of an emergency or occasional need.</p>
								</Accordion>

								<Accordion
									renderTitle={()=><h3>Do you care for people with dementia?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
								>
									<p>Yes, we ensure our carers who work with clients with dementia receive specialist training to ensure they are equipped with the right skills and approaches to provide the best possible quality of care. If you would like more information about dementia care, please contact one of our care specialists on 020 3970 9900.</p>
								</Accordion>
								<Accordion
									renderTitle={()=><h3>What accomodation and food do I need to provide?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
								>
									<p>Our live-in carers will need their own room to provide them with privacy and a comfortable living space. A spare room normally works well for this. If a client is concerned that the carer may not hear them at night, we have technical aids which can assist to address this concern.</p>
									<p>Many of our Professional Carers love to cook and good food is very much part of keeping us happy and healthy. In many instances clients and Professional Carers eat together. Dining is a very social experience. Some of our clients still love to cook sometimes too but we all have our favourite foods and dietary requirements and these are often unique to us, so sharing the same foods does not always work. If an agreement is not appropriate in matching foods then a £35 per week food allowance will be charged and given to the carer. The £35 allowance covers main food items like meat/vegetarian substitutes. When cooking in a client’s home, it's important our live-in carers are sensitive to cultural differences and the appropriateness of preparing highly fragranced foods.</p>
								</Accordion>
								<Accordion
									renderTitle={()=><h3>Are you regulated by the Care Quality Commission?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
								>
									<p>At Edyn, we are very proud of our Care Quality Commission (CQC) registration and have worked hard to receive a score of ‘Good’ (Rated May 2021). If you are unhappy with your current care provider or worried about the CQC status please call edyn.care on 020 3970 9900.</p>
								</Accordion>
								<Accordion
									renderTitle={()=><h3>Who is the Care Quality Commission?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
								>
									<p>The CQC is a body that regulates care provision in the UK for both care homes and domiciliary care (at home) providers, as well as GP practices, hospitals, and dentists. They review each care agency and set strict standards which providers have to adhere to. </p>
									<p>Please read more about the Care Quality Commission report <a href="https://www.cqc.org.uk/location/1-5584086807/inspection-summary" target="_blank" rel="noopener"><b>here</b></a>.</p>
								</Accordion>
								<Accordion
									renderTitle={()=><h3>How can I pay and how will I be charged?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
								>
									<p>Our invoices are submitted fortnightly on Monday afternoon. All invoices are based on actual work done and not forecasts, as we realise that care needs can and do change at short notice.</p>
									<p>Payments are taken automatically on Tuesday afternoon or the day after an invoice is submitted to you. In the event of any query or discrepancy, we will pause any pending payments. If payments have already gone through before you submit a query that payment can be refunded. However, we strongly encourage all customers to check their invoices straight away to avoid any delays or difficulties making the required adjustments.</p>
								</Accordion>
								<Accordion
									renderTitle={()=><h3>What areas do you operate?</h3>}
									renderIcon={()=><LiveInCareChevron className="chevron__liveincare"/>}
									indentation={0}
								>
									<p>We provide Live-in Care across England and soon to be across the United Kingdom. Please call our care specialists if you have any questions on 020 3970 9900.</p>
								</Accordion>
							</div>
						</div>
						<hr className="wide-view" />
					</section>
					{enableBlogContent && (
						<>
							<NewsblockSection className="newsblock-latest">
								<NewsblockContent>
									<NewsblockTitle center displaySmall>
										Learning more about live-in care
									</NewsblockTitle>
									<NewsblockBlocks>
										{newsblockData.map(({ title, slug }) => (
											<Newsblock
												displayShort
												color="yellow"
												title={title}
												link={`/blog/${slug}/`}>
											</Newsblock>
										))}
									</NewsblockBlocks>
								</NewsblockContent>
							</NewsblockSection>
						</>
					)}
					<hr className="liveincare_mobile_only"></hr>
					<section>
						<CqcApproved />
					</section>
				</main>
                <Footer />
			</div>
		</>
	);
}
