import React, {useEffect, useCallback, useState} from "react";
import { useSwipeable } from "react-swipeable"

import "./carousel.scss";

export const CarouselComponent = ({children, className, title}) => {
    const [activeItem, setActiveItem] = useState(0);
    const slidesCount = children.length;
    useEffect(()=>{
        const id = setTimeout(()=> {
          updateActiveSlide(activeItem + 1);
        }, 7000)

        return () => {
            clearTimeout(id);
        };
    }, [activeItem])

    const updateActiveSlide = useCallback((targetSlide) => {
      if (targetSlide >= slidesCount) {
        return setActiveItem(0);
      }
      if (targetSlide < 0) {
        return setActiveItem(slidesCount - 1);
      }
      return setActiveItem(targetSlide);
    }, [setActiveItem])

    const handlers = useSwipeable({
      onSwipedLeft: () => updateActiveSlide(activeItem + 1),
      onSwipedRight: () => updateActiveSlide(activeItem - 1),
      trackMouse: true,
      trackTouch: true
    });

  return (
    <section {...handlers} className={`carousel ${className}`}>
      <div className="carousel-container">
        {title && <h1>{title}</h1>}
        <div className="carousel-slides-container">
          {children.map((slide, index)=>{
            return (
              <div key={index} className={activeItem === index ? 'active-slide' : 'inactive-slide'}>
                {slide}
              </div>
            )
          })} 
        </div>
        <div className="carousel-dots">
          {children.map((slide, index) => (
            <span
              style={
                activeItem === index
                  ? {
                      backgroundColor: "#1c4042",
                    }
                  : {}
              }
              onClick={() => setActiveItem(index)}
              onKeyDown={() => setActiveItem(index)}
              aria-hidden="true"
              key={index}
              className="dot"
            ></span>
          ))}
        </div>
      </div>
    </section>
  );
};

export const CarouselComponentSlide = ({children, slideName, slideText}) => {
  return (
    <div className="slide">
      <>
        <div className="slide-image">
          {children}
        </div>
        <div className="slide-description">
            <h3>{slideName}</h3>
            <br/>
            <p className="primary">{slideText}</p>
        </div>
      </>
    </div>
  );
};